.sbs-toast-container {
    position: fixed;
    bottom: 50px;
    right: 0;
    width: 350px;
    height: auto;
    z-index: 2000;

    .slide-in {
        animation-name: fade-in;
        animation-fill-mode: forwards;
        animation-duration: 0.1s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
    }

    .slide-out {
        animation-name: fade-out;
        animation-fill-mode: forwards;
        animation-duration: 0.1s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}